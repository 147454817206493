<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>

<ion-grid class="full-grid">
  <ion-row>
    <div class="header"></div>
  </ion-row>
  <ion-row style="margin-top: -6rem !important">
    <ion-col
      size-xs="12"
      size-sm="12"
      size-md="12"
      class="margin-col-start margin-col-end"
    >
      <!-- Listado de indicadores -->
      <p-card>
        @if(!loading){
        <ion-col size-xs="12" size-sm="12" size-md="6" class="margin-col-end">
          <div class="spinner-container-indicator">
            <ion-spinner name="dots"></ion-spinner>
            <ion-label>Cargando...</ion-label>
          </div>
        </ion-col>
        } @else {
        <div slot="header" class="header-with-buttons">
          <h3>Resumen indicadores: doble materialidad</h3>
          <p-button
            label="Gestionar grupos"
            (click)="handleAddButton($event)"
          ></p-button>
        </div>

        <!-- Matriz de riesgos -->
        <div class="w-full mb-1">
          <ng-container
            *ngFor="let item of groupsWithDmData; trackBy: trackById"
          >
            <p-accordion class="accordion-indicator">
              <p-accordionTab [header]="item.name">
                <div class="risk-matrix">
                  <!-- Encabezados de columnas -->
                  <div class="header-row">
                    <div class="header-cell"></div>
                    <div class="header-cell" *ngFor="let impact of impacts">
                      {{ impact }}
                    </div>
                  </div>
                  <!-- Filas de la matriz -->
                  <div
                    class="row"
                    *ngFor="let financialRelevance of financialRelevances; let rowIndex = index"
                  >
                    <div class="cell header-cell">{{ financialRelevance }}</div>
                    <div
                      class="cell"
                      *ngFor="let impact of impacts; let colIndex = index"
                      [ngClass]="getCellClass(rowIndex, colIndex)"
                    >
                      <div class="chip-container">
                        <!-- Verificamos si hay datos en la celda -->
                        <ng-container
                          *ngIf="
                            item.matrixData[financialRelevance] &&
                            item.matrixData[financialRelevance][impact]
                          "
                        >
                          <!-- Iteramos sobre los registros en la celda -->
                          <p-chip
                            *ngFor="let dm of item.matrixData[financialRelevance][impact]"
                            label="{{ 'P' + dm.indicator.id }}"
                            styleClass="custom-chip"
                          ></p-chip>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </p-accordionTab>
            </p-accordion>
          </ng-container>
        </div>

        <!--inicio bucle primer nivel-->
        <p-accordion *ngFor="let parentKey of model['items']">
          <p-accordionTab>
            <ng-template pTemplate="header">
              <i class=" {{ parentKey.icon }}"></i>
              <span
                class="ml-2"
                style="font-family: 'Inter var', sans-serif !important"
              >
                {{ parentKey.label }}
              </span>
            </ng-template>

            <!--inicio bucle segundo nivel-->
            <p-accordion *ngFor="let childKey of parentKey['items']">
              <p-accordionTab>
                <ng-template pTemplate="header">
                  <i class=" {{ childKey.icon }}"></i>
                  <span
                    class="ml-2"
                    style="
                      font-weight: 400 !important;
                      font-family: 'Inter var', sans-serif !important;
                    "
                  >
                    {{ childKey.label }}
                  </span>
                </ng-template>

                <!--inicio bucle tercer nivel-->
                <p-accordion *ngFor="let grandchild of childKey['items']">
                  <p-accordionTab>
                    <ng-template pTemplate="header">
                      <i class=" {{ grandchild.icon }}"></i>
                      <span
                        class="ml-2"
                        style="
                          font-weight: 400 !important;
                          font-family: 'Inter var', sans-serif !important;
                        "
                      >
                        {{ grandchild.label }}
                      </span>
                    </ng-template>

                    <!--inicio bucle indicadores-->
                    <p
                      *ngFor="let indicator of grandchild.indicatorNames"
                      class="list-item"
                    >
                      <i class="pi pi-sign-in">
                        <span
                          class="ml-2"
                          style="
                            font-weight: 400 !important;
                            font-family: 'Inter var', sans-serif !important;
                          "
                        >
                          {{ indicator.name }}
                        </span>
                      </i>
                      <p-button
                        [loading]="loadingModal"
                        label="Ver doble materialidad"
                        icon="pi pi-eye"
                        (click)="showDualMaterialityDialog(indicator)"
                      ></p-button>
                    </p>
                  </p-accordionTab>
                </p-accordion>
              </p-accordionTab>
            </p-accordion>
          </p-accordionTab>
        </p-accordion>
        }
      </p-card>
    </ion-col>
  </ion-row>
</ion-grid>

<!-- Modal para cumplimentar doble materialidad -->
<p-dialog
  header="Doble materialidad"
  [(visible)]="dialogDualMaterialityForm"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '80vw' }"
  (onHide)="handleHideDualMateriality()"
>
  <p-stepper>
    <!-- Paso 0 selección de grupo -->
    <p-stepperPanel header="Paso 1">
      <ng-template
        pTemplate="content"
        let-nextCallback="nextCallback"
        let-index="index"
      >
        <div class="">
          <ion-grid>
            <ion-row>
              <ion-col size-xs="12" size-sm="12" size-md="12">
                <label for="">*Seleccione un grupo</label>
                <div class="mt-1">
                  <p-dropdown
                    [filter]="true"
                    appendTo="body"
                    [options]="dmGroups"
                    optionLabel="name"
                    optionValue="id"
                    [(ngModel)]="selectedGroup"
                    placeholder="Seleccione un grupo"
                  ></p-dropdown>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <div class="flex pt-4 justify-content-end">
          <p-button
            label="Siguiente"
            icon="pi pi-arrow-right"
            iconPos="right"
            (onClick)="nextCallback.emit()"
          />
        </div>
      </ng-template>
    </p-stepperPanel>

    <!-- Paso 1 -->
    <p-stepperPanel header="Paso 2">
      <ng-template
        pTemplate="content"
        let-nextCallback="nextCallback"
        let-index="index"
      >
        <!-- Formulario paso 1 -->
        <form [formGroup]="form1">
          <div class="">
            <ion-grid>
              <div class="header-container">
                <p>
                  <b>Análisis del entorno: </b> este paso inicial implica un
                  análisis profundo del entorno en el que opera la empresa
                </p>
              </div>
              <ion-row>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Regulaciones</label>
                  <textarea
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }"
                    class="w-full mb-3 mt-1"
                    rows="5"
                    pInputTextarea
                    formControlName="regulations_text"
                  ></textarea>
                  <p-fileUpload
                    name="file"
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    [files]="getUploadedFiles(1, 1)"
                    (onSelect)="onFileSelect($event, 1, 1)"
                    (onRemove)="deleteFileSelected($event,1, 1)"
                    accept=".pdf"
                  ></p-fileUpload>
                </ion-col>

                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Tendencias sectoriales</label>
                  <textarea
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="5"
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }"
                    formControlName="tendencies_text"
                  ></textarea>
                  <p-fileUpload
                    name="file"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    [files]="getUploadedFiles(1, 2)"
                    (onSelect)="onFileSelect($event, 1, 2)"
                    (onRemove)="deleteFileSelected($event, 1, 2)"
                    accept=".pdf"
                  ></p-fileUpload>
                </ion-col>
                <ion-col size-xs="12" size-sm="12" size-md="12">
                  <label for="">Expectativas de las partes interasadas</label>
                  <textarea
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="5"
                    formControlName="expectations_text"
                  ></textarea>
                  <p-fileUpload
                    name="file"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    (onSelect)="onFileSelect($event, 1, 3)"
                    [files]="getUploadedFiles(1, 3)"
                    (onRemove)="deleteFileSelected($event, 1, 3)"
                    accept=".pdf"
                  ></p-fileUpload>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </form>

        <div class="flex pt-4 justify-content-between">
          <p-button
            label="Atrás"
            icon="pi pi-arrow-left"
            (onClick)="prevCallback.emit()"
          />
          <p-button
            label="Siguiente"
            icon="pi pi-arrow-right"
            iconPos="right"
            (onClick)="nextCallback.emit()"
          />
        </div>
      </ng-template>
    </p-stepperPanel>

    <!-- Paso 2 -->
    <p-stepperPanel header="Paso 3">
      <ng-template
        pTemplate="content"
        let-prevCallback="prevCallback"
        let-nextCallback="nextCallback"
        let-index="index"
      >
        <!-- Formulario paso 2 -->
        <form [formGroup]="form2">
          <div class="">
            <ion-grid>
              <div class="header-container">
                <p>
                  <b>Recolección de información: </b>este paso implica la
                  recopilación de datos y la consulta con las partes interesadas
                  para identificar los temas relevantes
                </p>
              </div>
              <ion-row>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Consultas internas</label>
                  <textarea
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="5"
                    formControlName="internal_consultations_text"
                  ></textarea>
                  <p-fileUpload
                    name="file"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    [files]="getUploadedFiles(2, 1)"
                    (onSelect)="onFileSelect($event, 2, 1)"
                    (onRemove)="deleteFileSelected($event, 2, 1)"
                    accept=".pdf"
                  ></p-fileUpload>
                </ion-col>

                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Consultas externas</label>
                  <textarea
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="5"
                    formControlName="external_consultations_text"
                  ></textarea>
                  <p-fileUpload
                    name="file"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    [files]="getUploadedFiles(2, 2)"
                    (onSelect)="onFileSelect($event, 2, 2)"
                    (onRemove)="deleteFileSelected($event, 2, 2)"
                    accept=".pdf"
                  ></p-fileUpload>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </form>

        <div class="flex pt-4 justify-content-between">
          <p-button
            label="Atrás"
            icon="pi pi-arrow-left"
            (onClick)="prevCallback.emit()"
          />
          <p-button
            label="Siguiente"
            icon="pi pi-arrow-right"
            iconPos="right"
            (onClick)="nextCallback.emit()"
          />
        </div>
      </ng-template>
    </p-stepperPanel>

    <!-- Paso 3 -->
    <p-stepperPanel header="Paso 4">
      <ng-template
        pTemplate="content"
        let-prevCallback="prevCallback"
        let-nextCallback="nextCallback"
        let-index="index"
      >
        <!-- Formulario paso 3 -->
        <form [formGroup]="form3">
          <div class="">
            <ion-grid>
              <div class="header-container">
                <p>
                  <b>Evaluación de impacto y relevancia</b>
                </p>
              </div>
              <ion-row>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Impacto</label>
                  <div class="mt-1">
                    <p-dropdown
                      formControlName="impact"
                      appendTo="body"
                      [options]="numberScale"
                      optionLabel="label"
                      [style]="{'width':'100%'}"
                      [inputStyle]="{'width':'100%'}"
                      optionValue="value"
                      placeholder="Selecciona un valor"
                    >
                    </p-dropdown>
                  </div>
                  <textarea
                    class="w-full mb-3 mt-1"
                    rows="5"
                    pInputTextarea
                    formControlName="impact_text"
                  ></textarea>
                  <p-fileUpload
                    name="file"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    [files]="getUploadedFiles(3, 1)"
                    (onSelect)="onFileSelect($event, 3, 1)"
                    (onRemove)="deleteFileSelected($event, 3, 1)"
                    accept=".pdf"
                  ></p-fileUpload>
                </ion-col>

                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Relevancia financiera</label>
                  <div class="mt-1">
                    <p-dropdown
                      formControlName="financial_relevance"
                      appendTo="body"
                      [options]="numberScale"
                      optionLabel="label"
                      [style]="{'width':'100%'}"
                      [inputStyle]="{'width':'100%'}"
                      optionValue="value"
                      placeholder="Selecciona un valor"
                    >
                    </p-dropdown>
                  </div>
                  <textarea
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="5"
                    formControlName="financial_relevance_text"
                  ></textarea>
                  <p-fileUpload
                    name="file"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    [files]="getUploadedFiles(3, 2)"
                    (onSelect)="onFileSelect($event, 3, 2)"
                    (onRemove)="deleteFileSelected($event, 3, 2)"
                    accept=".pdf"
                  ></p-fileUpload>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </form>
        <div>
          <small
            >*Debe asignar un valor para impacto y relevancia financiera para
            que el indicador se muestre en la matriz de riesgos</small
          >
        </div>
        <div class="flex pt-4 justify-content-between">
          <p-button
            label="Atrás"
            icon="pi pi-arrow-left"
            (onClick)="prevCallback.emit()"
          />
          <p-button
            label="Siguiente"
            icon="pi pi-arrow-right"
            iconPos="right"
            (onClick)="nextCallback.emit()"
          />
        </div>
      </ng-template>
    </p-stepperPanel>

    <!-- Paso 4 -->
    <p-stepperPanel header="Paso 5">
      <ng-template
        pTemplate="content"
        let-prevCallback="prevCallback"
        let-nextCallback="nextCallback"
        let-index="index"
      >
        <!-- Formulario paso 4 -->
        <form [formGroup]="form4">
          <div class="">
            <ion-grid>
              <ion-row>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Upstream y Downstream</label>
                  <textarea
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="5"
                    formControlName="up_down_text"
                  ></textarea>
                  <p-fileUpload
                    name="file"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    [files]="getUploadedFiles(4, 1)"
                    (onSelect)="onFileSelect($event, 4, 1)"
                    (onRemove)="deleteFileSelected($event, 4, 1)"
                    accept=".pdf"
                  ></p-fileUpload>
                </ion-col>

                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Evaluaciones de Ciclo de Vida (LCA)</label>
                  <textarea
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="5"
                    formControlName="lca_text"
                  ></textarea>
                  <p-fileUpload
                    name="file"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    [files]="getUploadedFiles(4, 2)"
                    (onSelect)="onFileSelect($event, 4, 2)"
                    (onRemove)="deleteFileSelected($event, 4, 2)"
                    accept=".pdf"
                  ></p-fileUpload>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </form>

        <div class="flex pt-4 justify-content-between">
          <p-button
            label="Atrás"
            icon="pi pi-arrow-left"
            (onClick)="prevCallback.emit()"
          />
          <p-button
            label="Siguiente"
            icon="pi pi-arrow-right"
            iconPos="right"
            (onClick)="nextCallback.emit()"
          />
        </div>
      </ng-template>
    </p-stepperPanel>

    <!-- Paso 5 -->
    <p-stepperPanel header="Paso 6">
      <ng-template
        pTemplate="content"
        let-prevCallback="prevCallback"
        let-nextCallback="nextCallback"
        let-index="index"
      >
        <!-- Formulario paso 5 -->
        <form [formGroup]="form5">
          <div class="">
            <ion-grid>
              <ion-row>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Transparencia y exactitud</label>
                  <textarea
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="5"
                    formControlName="transparency_text"
                  ></textarea>
                  <p-fileUpload
                    name="file"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    [files]="getUploadedFiles(5, 1)"
                    (onSelect)="onFileSelect($event, 5, 1)"
                    (onRemove)="deleteFileSelected($event, 5, 1)"
                    accept=".pdf"
                  ></p-fileUpload>
                </ion-col>

                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Conformidad con estándares</label>
                  <textarea
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="5"
                    formControlName="accordance_text"
                  ></textarea>
                  <p-fileUpload
                    name="file"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    [files]="getUploadedFiles(5, 2)"
                    (onSelect)="onFileSelect($event, 5, 2)"
                    (onRemove)="deleteFileSelected($event,5 , 2)"
                    accept=".pdf"
                  ></p-fileUpload>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </form>

        <div class="flex pt-4 justify-content-between">
          <p-button
            label="Atrás"
            icon="pi pi-arrow-left"
            (onClick)="prevCallback.emit()"
          />
          <p-button
            label="Guardar"
            iconPos="right"
            [loading]="loadingForm"
            (click)="onSubmitForm()"
          />
        </div>
      </ng-template>
    </p-stepperPanel>
  </p-stepper>
</p-dialog>

<!-- Modal para selección de formulario -->
<p-dialog
  header="Seleccione una opción"
  [(visible)]="dialogForm"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '40vw' }"
>
  <div class="p-4 align-items-center justify-content-center flex w-full gap-3">
    <p-button
      label="Doble materialidad a múltiples indicadores"
      (click)="formSelected(1)"
    ></p-button>
    <p-button
      label="Gestionar grupos para doble materialidad"
      (click)="formSelected(2)"
    ></p-button>
  </div>
</p-dialog>

<!-- Modal para historico de DM -->
<p-dialog
  header="Doble materialidad del indicador"
  [(visible)]="dialogDualMateriality"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '70vw' }"
>
  <p-table
    #dt1
    styleClass="p-datatable-striped"
    responsiveLayout="scroll"
    [value]="dmData"
    [rowHover]="true"
    [paginator]="true"
    [rows]="5"
    [globalFilterFields]="['name']"
  >
    <ng-template pTemplate="caption">
      <div
        style="gap: 1vw"
        class="flex justify-content-center align-items-center sm:flex-row"
      >
        <input
          pInputText
          type="text"
          #filterGeneral
          (input)="dt1.filterGlobal($event.target.value, 'contains')"
          placeholder="Buscar registro"
          class="w-full"
        />
        <p-button
          icon="pi pi-plus"
          pTooltip="Nuevo registro de doble materialidad"
          (click)="showDualMaterialityFormDialog()"
        ></p-button>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>ID</th>
        <th>Grupo</th>
        <th>Formulario</th>
        <th>Fecha</th>
        <th>Opciones</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-dm>
      <tr>
        <td>{{ dm.id }}</td>
        <td>{{ dm.dual_materiality_group.name }}</td>
        <td>
          <p-tag
            [severity]="dm.isComplete ? 'success' : 'warning'"
            [value]="dm.isComplete ? 'Completo' : 'Incompleto'"
          />
        </td>
        <td>{{ dm.created_at | date: 'dd/MM/YY HH:mm'}}</td>
        <td>
          <p-button
            class="m-1"
            pTooltip="Editar registro"
            icon="pi pi-pencil"
            (click)="showDualMaterialityFormDialog(dm)"
          ></p-button>
          <p-button
            class="m-1"
            severity="danger"
            pTooltip="Eliminar registro"
            icon="pi pi-trash"
            (click)="deleteDm(dm)"
          ></p-button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7">No se encontraron registros</td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>

<!-- Modal para gestionar grupos -->
<p-dialog
  header="Gestión de grupos para doble materialidad"
  [(visible)]="dialogGroup"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '60vw' }"
>
  <p-table
    #dt1
    styleClass="p-datatable-striped"
    responsiveLayout="scroll"
    [value]="dmGroups"
    [rowHover]="true"
    [paginator]="true"
    [rows]="5"
    [globalFilterFields]="['name']"
  >
    <ng-template pTemplate="caption">
      <div
        style="gap: 1vw"
        class="flex justify-content-center align-items-center sm:flex-row"
      >
        <input
          pInputText
          type="text"
          #filterGeneral
          (input)="dt1.filterGlobal($event.target.value, 'contains')"
          placeholder="Buscar grupo"
          class="w-full"
        />
        <p-button
          icon="pi pi-plus"
          pTooltip="Nuevo grupo"
          (click)="showDialog()"
        ></p-button>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>Nombre del grupo</th>
        <th>Opciones</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-group>
      <tr>
        <td>{{ group.name }}</td>
        <td>
          <p-button
            class="m-1"
            pTooltip="Editar grupo"
            icon="pi pi-pencil"
            (click)="showDialog(group)"
          ></p-button>
          <p-button
            class="m-1"
            severity="danger"
            pTooltip="Eliminar grupo"
            icon="pi pi-trash"
            (click)="deleteGroup(group)"
          ></p-button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7">No se encontraron grupos</td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>

<!-- Modal para crear/editar grupos -->
<p-dialog
  header="Gestión de grupos para doble materialidad"
  [(visible)]="dialogGroupForm"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '60vw' }"
>
  <form [formGroup]="formDMGroup">
    <ion-grid>
      <ion-row>
        <ion-col size-xs="12" size-sm="12" size-md="12">
          <label for="">*Nombre del grupo</label>
          <input
            type="text"
            class="mb-3 w-full"
            [style]="{ width: '100%' }"
            pInputText
            formControlName="name"
          />
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
  <ng-template pTemplate="footer">
    <p-button
      label="Guardar"
      [loading]="loadingForm"
      (click)="onSubmitGroupForm()"
    >
    </p-button>
  </ng-template>
</p-dialog>

<!-- Modal de eliminación de DM -->
<p-dialog
  header="Eliminar registro de doble materialidad"
  [(visible)]="dialogDeleteDm"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [closable]="true"
>
  <div class="flex flex-column align-items-center text-center">
    <p class="mb-3">¿Está seguro de que desea eliminar este registro?</p>
    <p class="mb-4">
      Recuerde que esta acción no se puede deshacer y afectará los datos
      relacionados.
    </p>
    <div class="flex justify-content-center gap-3">
      <p-button
        [loading]="loadingModalBtn"
        label="Sí"
        icon="pi pi-check"
        (click)="onClickDeleteDm(true)"
      ></p-button>
      <p-button
        [loading]="loadingModalBtn"
        label="No"
        icon="pi pi-times"
        (click)="onClickDeleteDm(false)"
      ></p-button>
    </div>
  </div>
</p-dialog>

<!-- Modal de eliminación de grupo DM -->
<p-dialog
  header="Eliminar grupo de doble materialidad"
  [(visible)]="dialogDeleteGroup"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [closable]="true"
>
  <div class="flex flex-column align-items-center text-center">
    <p class="mb-3">¿Está seguro de que desea eliminar este grupo?</p>
    <p class="mb-4">
      Recuerde que esta acción no se puede deshacer y afectará a todos los
      indicadores del grupo y sus datos
    </p>
    <div class="flex justify-content-center gap-3">
      <p-button
        [loading]="loadingModalBtn"
        label="Sí"
        icon="pi pi-check"
        (click)="onClickDeleteGroup(true)"
      ></p-button>
      <p-button
        [loading]="loadingModalBtn"
        label="No"
        icon="pi pi-times"
        (click)="onClickDeleteGroup(false)"
      ></p-button>
    </div>
  </div>
</p-dialog>
